import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueApexCharts from 'vue-apexcharts'
// import excel from 'vue-excel-export'
import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(Vuetify);
Vue.use(VueApexCharts);
// Vue.use(excel);
Vue.use(VueExcelXlsx);

export default new Vuetify({
    // theme: {
    //     success: {
    //         lighter: '#E9FCD4',
    //         light: '#AAF27F',
    //         main: '#54D62C',
    //         dark: '#229A16',
    //         darker: '#08660D',
    //     }
    // },
    theme: {
      // dark: true,
        themes: {
          light: {
            // primary: '#54D62C',
            secondary: '#E9FCD4',
            accent: '#8c9eff',
            error: '#b71c1c',
          },
        },
    },

});
